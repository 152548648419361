import { successNoitif } from '../utils/notifications';
import { Post } from './request';

export const signinUser = async (username, password) => {
  const response = await Post('/auth/sign-in', { username, password });
  if (response) {
    successNoitif(response.msg);
    return {
      token: response.sessionToken,
      user: response.user,
    };
  }
  return null;
};

export const logoutUser = async () => {
  const response = await Post('/auth/logout');
  if (response) {
    return true;
  }

  return false;
};
