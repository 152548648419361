import { CopyAll } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ApiAccessTokenDoc from '../components/docs/ApiAccessTokenDoc';
import DocPlayground from '../components/docs/DocPlayground';
import IntroductionDoc from '../components/docs/IntroductionDoc';
import { DOCS } from '../constants/app-urls';
import { NEWPORT_DOC_ITEMS, TMS_DOC_ITEMS } from '../constants/doc-items';
import {
  NEWPORT_MENU_OPTIONS,
  TMS_DOC_MENU_OPTIONS,
} from '../constants/docs-menu-options';
import Screen from '../widgets/Screen';
import Tab from '../widgets/Tab';
import Tabs from '../widgets/Tabs';
const baseURL = process.env.REACT_APP_API_BASEURL;

const RenderDocBody = ({ pathTitle, doc }) => {
  const [activeExampleLanguage, setActiveExampleLanguage] = useState(0);
  const activeExample = doc?.requestExamples?.[activeExampleLanguage];

  const handleExampleTabSelection = (event, value) =>
    setActiveExampleLanguage(value);

  const formatUrl = (url, title) => {
    let tempUrl = url?.replace('{{base_url}}', baseURL).replace('/v1', '');

    return (
      <pre className="url" id={`${title}-${url}`}>
        {tempUrl}
      </pre>
    );
  };

  const getStatusColor = (type) => {
    const colors = {
      SUCCESS: '#59CE8F',
      ERROR: '#FF6363',
      'BAD REQUEST': '#FF6363',
    };

    return colors[type || 'ERROR'];
  };

  const copyUrlToClipBoard = (selector) => {
    const element = document.getElementById(selector);
    navigator.clipboard.writeText(element.innerText);
  };

  const copyCodeToClipBoard = (selector) => {
    const element = document.getElementById(selector);
    navigator.clipboard.writeText(element.innerText);
  };

  const getMethodColor = (method) => {
    const colors = {
      POST: '#59CE8F',
      GET: '#3AB0FF',
      PATCH: '#CCF3EE',
      PUT: '#CCF3EE',
      DELETE: '#FF6363',
    };

    return colors[method || 'POST'];
  };

  if (pathTitle === 'Getting Started') {
    return <IntroductionDoc />;
  } else if (pathTitle === 'API Access Token') {
    return <ApiAccessTokenDoc />;
  } else {
    return (
      doc && (
        <Stack key={doc.anchor} id={doc.anchor} spacing={1.5} mb={10}>
          <Typography variant="h4" fontWeight={700}>
            {doc.title}
          </Typography>
          <Typography
            fontWeight={400}
            maxWidth={'100%'}
            color="#303030"
            fontSize={14}>
            {doc.description}
          </Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="body1"
              fontWeight={'100%'}
              fontSize={12}
              sx={{
                backgroundColor: getMethodColor(doc.method),
                px: 1,
                py: 0.2,
                color: '#303030',
                borderRadius: 1,
                height: 'max-content',
              }}>
              {doc.method}
            </Typography>
            {formatUrl(doc.url, doc.title)}
            <IconButton
              title="Copy url"
              onClick={() => copyUrlToClipBoard(`${doc.title}-${doc.url}`)}>
              <CopyAll />
            </IconButton>
          </Stack>
          {doc?.params && (
            <>
              <Typography fontSize={20} fontWeight={700}>
                Path Parameters
              </Typography>
              <Table
                size="small"
                sx={{ border: '2px solid rgba(0,0,0,0.1)', maxWidth: '100%' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Parameter</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doc?.params?.map((param) => (
                    <TableRow key={param.name}>
                      <TableCell>{param.type}</TableCell>
                      <TableCell>{param.name}</TableCell>
                      <TableCell>{param.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          )}
          <Typography fontSize={20} fontWeight={700}>
            Body Parameters
          </Typography>
          <Table
            size="small"
            sx={{ border: '2px solid rgba(0,0,0,0.1)', maxWidth: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Parameter</TableCell>
                <TableCell>Example</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doc.bodyParams.map((param) => (
                <TableRow key={param.name}>
                  <TableCell>{param.type}</TableCell>
                  <TableCell>{param.name}</TableCell>
                  <TableCell>
                    <pre className="code">{param.value}</pre>
                  </TableCell>
                  <TableCell>{param.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography fontSize={20} fontWeight={700}>
              Example ({activeExample?.language})
            </Typography>
            <IconButton
              title="Copy code"
              onClick={() =>
                copyCodeToClipBoard(`${doc.title}-${doc.url}-code`)
              }>
              <CopyAll />
            </IconButton>
          </Stack>
          <Tabs
            onChange={handleExampleTabSelection}
            value={activeExampleLanguage}>
            {doc.requestExamples?.map((item) => (
              <Tab label={item.language} key={item.language} />
            ))}
          </Tabs>
          <pre className="code">{activeExample?.sampleCode}</pre>
          <Typography fontSize={20} fontWeight={700}>
            API Response
          </Typography>
          {doc.returnBody.map((item) => (
            <Stack spacing={1} key={item.status}>
              <Stack direction="row" spacing={1}>
                <Typography
                  variant="body1"
                  fontWeight={'100%'}
                  fontSize={14}
                  sx={{
                    backgroundColor: getStatusColor(item.type),
                    px: 1,
                    py: 0.2,
                    color: '#303030',
                    borderRadius: 1,
                    height: 'max-content',
                  }}>
                  {item.type}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={'100%'}
                  fontSize={14}
                  sx={{
                    backgroundColor: getStatusColor(item.type),
                    px: 1,
                    py: 0.2,
                    color: '#303030',
                    borderRadius: 1,
                    height: 'max-content',
                  }}>
                  {item.status}
                </Typography>
              </Stack>
              <pre className="code">{JSON.stringify(item.body, null, 2)}</pre>
            </Stack>
          ))}
          <Divider orientation="horizontal" sx={{ maxWidth: '100%' }} />
          {!doc?.disablePlayground && (
            <Typography fontSize={20} fontWeight={700}>
              Playground
            </Typography>
          )}
          {!doc?.disablePlayground && (
            <DocPlayground
              testReqParams={doc?.testReqParams}
              fields={doc.fields}
              requestUrl={doc.url}
              method={doc.method}
            />
          )}
        </Stack>
      )
    );
  }
};
const Docs = ({ label }) => {
  const { title } = useParams();

  const user = useSelector((state) => state.auth.user);

  const [doc, setDoc] = useState(null);

  const menuOptions = () => {
    if (user.orgType === 'TMS') {
      return TMS_DOC_MENU_OPTIONS;
    } else if (user.orgType === 'NUPORT') {
      return NEWPORT_MENU_OPTIONS;
    } else if (user.isAdmin) {
      return [...TMS_DOC_MENU_OPTIONS, ...NEWPORT_MENU_OPTIONS];
    }

    return [];
  };

  useEffect(() => {
    if (title !== 'Getting Started' || title !== 'API Access Token') {
      const docItem = getDocItem();
      setDoc(docItem);
    }
  }, [title]);

  const getDocItem = () => {
    if (user.orgType === 'TMS') {
      return TMS_DOC_ITEMS.find((item) => item.title === title);
    } else if (user.orgType === 'NUPORT') {
      return NEWPORT_DOC_ITEMS.find((item) => item.title === title);
    } else if (user.isAdmin) {
      return [...TMS_DOC_ITEMS, ...NEWPORT_DOC_ITEMS].find(
        (item) => item.title === title,
      );
    }

    return [];
  };

  const getMethodColor = (method) => {
    const colors = {
      POST: '#59CE8F',
      GET: '#3AB0FF',
      PATCH: '#CCF3EE',
      PUT: '#CCF3EE',
      DELETE: '#FF6363',
    };

    return colors[method || 'POST'];
  };

  const isNavItemSelected = (navTitle) =>
    location.pathname.includes(`/docs/${encodeURI(navTitle)}`);

  return (
    <Screen>
      <Helmet>
        <title>BAT API HUB | {label}</title>
      </Helmet>
      <Stack width="100%" height="100%" direction="row">
        <Stack
          width={280}
          height="calc(100vh - 79px)"
          bgcolor="#0e2b63"
          boxSizing="border-box"
          px={2}
          py={2}
          sx={{
            position: 'sticky',
            top: 64,
            color: '#fff',
            cursor: 'pointer',
            overflow: 'auto',
          }}>
          <List>
            <ListItemText
              primary="Getting Started"
              sx={{ cursor: 'default' }}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 700 }}
            />
            <Collapse in={true}>
              <List component="div">
                <ListItemText
                  primary={
                    <Stack
                      component={Link}
                      to={`${DOCS}${encodeURI('/Getting Started')}`}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ textDecoration: 'none', color: '#fff' }}>
                      <Typography fontSize={12} fontWeight={400}>
                        Introduction
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    pl: 2,
                    py: 1,
                    bgcolor: isNavItemSelected('Getting Started')
                      ? '#344D67'
                      : 'transparent',
                  }}
                  primaryTypographyProps={{ fontSize: 12, fontWeight: 500 }}
                />
                <ListItemText
                  primary={
                    <Stack
                      component={Link}
                      to={`${DOCS}${encodeURI('/API Access Token')}`}
                      direction="row"
                      spacing={1}
                      alignItems="center"
                      sx={{ textDecoration: 'none', color: '#fff' }}>
                      <Typography fontSize={12} fontWeight={400}>
                        API Access Token
                      </Typography>
                    </Stack>
                  }
                  sx={{
                    pl: 2,
                    py: 1,
                    bgcolor: isNavItemSelected('API Access Token')
                      ? '#344D67'
                      : 'transparent',
                  }}
                  primaryTypographyProps={{ fontSize: 12, fontWeight: 500 }}
                />
              </List>
            </Collapse>
          </List>
          <List>
            <ListItemText
              primary="Use Cases"
              sx={{ cursor: 'default' }}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 700 }}
            />
            <Collapse in={true}>
              <List component="div">
                {menuOptions().map((item) => (
                  <ListItemText
                    key={item.location}
                    sx={{
                      pl: 2,
                      py: 1,
                      bgcolor: isNavItemSelected(item.name)
                        ? '#344D67'
                        : 'transparent',
                    }}
                    primary={
                      <Stack
                        component={Link}
                        to={`${DOCS}/${encodeURI(item.name)}`}
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        sx={{ textDecoration: 'none', color: '#fff' }}>
                        <Typography
                          variant="body1"
                          fontWeight={'100%'}
                          fontSize={10}
                          sx={{
                            backgroundColor: getMethodColor(item.method),
                            px: 1,
                            py: 0.2,
                            color: '#303030',
                            borderRadius: 1,
                          }}>
                          {item.method}
                        </Typography>
                        <Typography fontSize={12} fontWeight={400}>
                          {item.name}
                        </Typography>
                      </Stack>
                    }
                  />
                ))}
              </List>
            </Collapse>
          </List>
        </Stack>
        <Stack spacing={3} px={3} py={3} direction="row" width="100%">
          <Stack flexGrow={1}>
            <RenderDocBody pathTitle={title} doc={doc} />
          </Stack>
        </Stack>
      </Stack>
    </Screen>
  );
};

export default Docs;
