import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';

import Dropdown from '../../widgets/Dropdown';
import { authActions } from '../../store/reducers/auth-reducer';
import { logoutUser } from '../../services/auth';

const AvatarButton = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <Stack flexDirection="row" alignItems="center">
      <Avatar sx={{ bgcolor: '#404040' }}>
        <Typography fontSize="14px" color="white" fontWeight={700}>
          {user?.name.split(' ')[0][0]}
          {user?.name.split(' ')[1][0]}
        </Typography>
      </Avatar>
    </Stack>
  );
};

export const Navbar = () => {
  const dispatch = useDispatch();
  const profileMenuOptions = [
    {
      label: 'Logout',
      sectionBreakAbove: true,
      leftElem: (
        <PowerSettingsNew
          sx={{ height: '18px', width: '18px', marginRight: '5px' }}
        />
      ),
      callback: async () => {
        await logoutUser();
        dispatch(authActions.logout());
      },
    },
  ];

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box
          component="img"
          src="/assets/logo.png"
          height="40px"
          bgcolor="white"
          px={1}
          py={1}
          boxSizing="border-box"
          borderRadius={1}
        />
      </Box>
      <Box sx={{ flexGrow: 0 }}>
        <Dropdown options={profileMenuOptions} buttonElem={<AvatarButton />} />
      </Box>
    </>
  );
};
