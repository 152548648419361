import * as urls from './app-urls';

import { Dashboard, Dvr, Subject, Token } from '@mui/icons-material';

export const sidebarItems = [
  {
    label: 'Dashboard',
    path: urls.DASHBOARD,
    Icon: Dashboard,
    isDisabled: true,
  },
  {
    label: 'API Docs',
    path: urls.DOCS,
    Icon: Subject,
    isDisabled: false,
  },
  {
    label: 'API Credentials',
    path: urls.MANAGE_TOKEN,
    Icon: Token,
    isDisabled: false,
  },
  {
    label: 'Logs',
    path: urls.DASHBOARD + 55,
    Icon: Dvr,
    isDisabled: true,
  },
];

export const sidebarItems2 = [];
