import React from 'react';
import { Outlet } from 'react-router-dom';
import Branding from '../components/mic/Branding';
// import ErrorBoundary from '../components/mic/ErrorBoundary';

const PublicLayout = () => {
  return (
    // <ErrorBoundary>
    <>
      <Outlet />
      <Branding />
    </>
    // </ErrorBoundary>
  );
};

export default PublicLayout;
