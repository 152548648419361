export const TMS_DOC_MENU_OPTIONS = [
  {
    method: 'POST',
    name: 'Vehicle Departure',
    location: 'vehicle-departure',
  },
  {
    method: 'POST',
    name: 'Vehicle Arrival',
    location: 'vehicle-arrival',
  },

  // {
  //   method: 'POST',
  //   name: 'Vehicle Location Update',
  //   location: 'location',
  // },
];

export const NEWPORT_MENU_OPTIONS = [
  {
    method: 'POST',
    name: 'Create Order',
    location: 'orders',
  },
  {
    method: 'PUT',
    name: 'Update Order',
    location: 'orders',
  },
  {
    method: 'POST',
    name: 'Start Scanning',
    location: 'orders/scan/start/:orderId',
  },
  {
    method: 'POST',
    name: 'End Scanning',
    location: 'orders/scan/end/orderId',
  },
  {
    method: 'POST',
    name: 'Scan Item',
    location: 'orders/scan/:orderId',
  },
  {
    method: 'PATCH',
    name: 'Cancel Order',
    location: 'orders/cancel/:orderId',
  },
  {
    method: 'POST',
    name: 'Create Product',
    location: 'products',
  },
  {
    method: 'PATCH',
    name: 'Update Product',
    location: 'products',
  },
  {
    method: 'DELETE',
    name: 'Delete Product',
    location: 'products/:productId',
  },
];
