import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  List,
  ListItemText,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { MANAGE_TOKEN } from '../../constants/app-urls';

const ApiAccessTokenDoc = () => {
  return (
    <Stack spacing={1.5} mb={10} id="getting-started">
      <Typography variant="h4" fontWeight={700}>
        Authenticating using API Keys
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        The BAT API Hub utilizes a combination of <b>Public API Access Keys</b>{' '}
        and <b>Private Keys</b> to ensure that each API accessed within the BAT
        infrastructure is secure and can be tracked.
      </Typography>
      <Typography fontSize={20} fontWeight={700}>
        Generate API Key
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        First step is to head to the{' '}
        <Link to={MANAGE_TOKEN}>API Credentials</Link> tab and generate an{' '}
        <b>API Access Key</b>. There are two types of environments for which you
        can generate API keys :
      </Typography>
      <Table
        size="small"
        sx={{ border: '2px solid rgba(0,0,0,0.1)', maxWidth: '100%' }}>
        <TableBody>
          <TableRow>
            <TableCell>SANDBOX</TableCell>
            <TableCell>
              This is the key you shall be using while testing and within the
              BAT API Hub documentation.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PRODUCTION</TableCell>
            <TableCell>
              This is the key you shall be using during the production
              environment.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        The API Access Key is an encrypted alphanumeric string, for example :
      </Typography>
      <pre className="code">EIzaScDaGerWKa4JsXZ-KjGe7ISLn_3namBGewQe.</pre>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        This is the Public API Key that you will have access to, in addition to
        this we will also be sending a Private Key associated with this Public
        key. The private key will be visible to you only once after the key pair
        is generated. If you forget this key, you will have to regenerate a new
        key pair.
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        From the <Link to={MANAGE_TOKEN}>API Credentials</Link> tab, you can
        view a list of keys that were generated. Key generation follows certain
        rules :
      </Typography>
      <List>
        <ListItemText
          sx={{ pl: 4 }}
          primaryTypographyProps={{
            fontWeight: 600,
            maxWidth: '100%',
            fontSize: 14,
            color: '#303030',
          }}
          primary="1. You can have only 1 valid production API Access Key at a time. To generate a new one, you must first invalidate the current active key."
        />
        <ListItemText
          sx={{ pl: 4 }}
          primaryTypographyProps={{
            fontWeight: 600,
            maxWidth: '100%',
            fontSize: 14,
            color: '#303030',
          }}
          primary="2. Production keys have a request limit of 1000 requests/sec, followed by a 30 second cool down duration if the limit is exceeded."
        />
      </List>
      <Typography fontSize={20} fontWeight={700}>
        Using an API Key
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        To use the API Key, you need to utilize the ‘authorization’ header and a
        custom ‘x-batb-hub-api-key’ header along with your desired API request.
      </Typography>
      <Table
        size="small"
        sx={{ border: '2px solid rgba(0,0,0,0.1)', maxWidth: '100%' }}>
        <TableBody>
          <TableRow>
            <TableCell>API Access Key</TableCell>
            <TableCell>Send via custom header → ‘x-bat-hub-api-key’</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Private Key</TableCell>
            <TableCell>Send via authorization header as bearer token</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography fontSize={20} fontWeight={700}>
        Sample Request Header
      </Typography>
      <pre className="code">{`
curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d @request.json 
      `}</pre>
    </Stack>
  );
};

export default ApiAccessTokenDoc;
