import { createSlice } from '@reduxjs/toolkit';

const initState = {
  hasError: false,
  error: null,
};

const errorSlice = createSlice({
  name: 'errors',
  initialState: initState,
  reducers: {
    setError: (state, action) => {
      state.error = JSON.stringify(action.payload);
      state.hasError = true;
    },

    resetError: (state) => {
      state.error = null;
      state.hasError = false;
    },
  },
});

export const errorAction = errorSlice.actions;
export default errorSlice.reducer;
