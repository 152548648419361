import { toast } from 'react-toastify';

export const successNoitif = (msg) =>
  toast(msg, {
    position: 'bottom-center',
    theme: 'colored',
    type: 'success',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const errorNoitif = (msg) =>
  toast(msg, {
    position: 'bottom-center',
    autoClose: 5000,
    theme: 'colored',
    type: 'error',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const warningNoitif = (msg) =>
  toast(msg, {
    position: 'bottom-center',
    autoClose: 5000,
    theme: 'colored',
    type: 'warning',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
