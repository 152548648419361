const BASE_URL = '{{base_url}}/v1';

export const TMS_DOC_ITEMS = [
  {
    title: 'Vehicle Departure',
    description:
      'Once a vehicle has been loaded and begins its journey to its destination, as soon as it crosses the geo-fence for the source location, The TMS will send information via an API call to the BAT FG WMS stating that it has left the geo-fence.',
    anchor: 'vehicle-departure',
    method: 'POST',
    url: `${BASE_URL}/vehicle-location/{{vehicleId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'vehicleId',
        description: 'Vehicle identifier',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'geoFenceAction',
        value: 'exit',
        description:
          'Geo-fence action can be of 2 types : ‘exit’ or ‘entry’. Based on the action type, BAT FG WMS will do certain actions.',
      },
      {
        type: 'String',
        name: 'geoFenceId',
        value: '8398kd947s93j93',
        description: 'Unique identifier of Geo-fence',
      },
      {
        type: 'String',
        name: 'latitude',
        value: '23.787573784303444',
        description: 'Location Data of vehicle at that point',
      },
      {
        type: 'String',
        name: 'longitude',
        value: '90.39723380842224',
        description: 'Location Data of vehicle at that point',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'eta',
        value: '2022-09-06 07:30:54.953141+00',
        description:
          'Estimated time of arrival to destination at that particular moment',
      },
    ],
    returnBody: [
      {
        type: 'ERROR',
        status: 404,
        body: {
          statusCode: 404,
          msg: 'Sorry could not find vehicle information',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Internal Server Error',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Information received!',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
    geoFenceAction: 'exit',
    geoFenceId: '255024',
    latitude: '23.787573784303444',
    longitude: '90.39723380842224',
    eta: new Date(),
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
          'geoFenceAction': 'exit',
          'geoFenceId': '255024',
          'latitude': '23.787573784303444',
          'longitude': '90.39723380842224',
          'eta': 12-2-2022,
       }
`,
      },
    ],

    requestUrl: '/vehicle-location',
    testReqParams: [
      {
        type: 'string',
        name: 'vehicleId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'select',
        options: ['exit'],
        name: 'geoFenceAction',
        required: true,
      },
      {
        type: 'string',
        name: 'geoFenceId',
        required: true,
      },
      {
        type: 'string',
        name: 'latitude',
        required: true,
      },
      {
        type: 'string',
        name: 'longitude',
        required: true,
      },
      {
        type: 'date',
        name: 'eta',
        required: true,
      },
    ],
  },
  {
    title: 'Vehicle Arrival',
    description:
      'Once a vehicle has crossed the geo-fence for the destination location, The TMS will send information via an API call to the BAT FG WMS stating that it has entered the geo-fence. ',
    anchor: 'vehicle-arrival',
    method: 'POST',
    url: `${BASE_URL}/vehicle-location/{{vehicleId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'vehicleId',
        description: 'Vehicle identifier',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'geoFenceAction',
        value: 'exit',
        description:
          'Geo-fence action can be of 2 types : ‘exit’ or ‘entry’. Based on the action type, BAT FG WMS will do certain actions.',
      },
      {
        type: 'String',
        name: 'geoFenceId',
        value: '8398kd947s93j93',
        description: 'Unique identifier of Geo-fence',
      },
      {
        type: 'String',
        name: 'latitude',
        value: '23.787573784303444',
        description: 'Location Data of vehicle at that point',
      },
      {
        type: 'String',
        name: 'longitude',
        value: '90.39723380842224',
        description: 'Location Data of vehicle at that point',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'eta',
        value: '2022-09-06 07:30:54.953141+00',
        description:
          'Estimated time of arrival to destination at that particular moment',
      },
    ],
    returnBody: [
      {
        type: 'ERROR',
        status: 404,
        body: {
          status: 404,
          msg: 'Sorry could not find vehicle information',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          status: 500,
          msg: 'Internal Server Error',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          status: 200,
        },
      },
    ],

    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'
        
const requestBody = {
    geoFenceAction: 'exit',
    geoFenceId: '255024',
    latitude: '23.787573784303444',
    longitude: '90.39723380842224',
    eta: new Date(),
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
          'geoFenceAction': 'exit',
          'geoFenceId': '255024',
          'latitude': '23.787573784303444',
          'longitude': '90.39723380842224',
          'eta': 12-2-2022,
       }
`,
      },
    ],
    requestUrl: '/vehicle-location',
    testReqParams: [
      {
        type: 'string',
        name: 'vehicleId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'select',
        options: ['entry'],
        name: 'geoFenceAction',
        required: true,
      },
      {
        type: 'string',
        name: 'geoFenceId',
        required: true,
      },
      {
        type: 'string',
        name: 'latitude',
        required: true,
      },
      {
        type: 'string',
        name: 'longitude',
        required: true,
      },
      {
        type: 'date',
        name: 'eta',
        required: true,
      },
    ],
  },
];

export const NEWPORT_DOC_ITEMS = [
  {
    title: 'Create Order',
    description: `As soon as orders are entered into Symphony and saved, 
    a copy of those orders must be sent to BAT PT. The endpoint will support 
    multiple orders being sent at the same time in the same request body. 
    Even if it's one order, you will have to send it as an array with one object.
`,
    anchor: 'orders',
    method: 'POST',
    url: `${BASE_URL}/orders`,
    // params: [
    //   {
    //     type: 'Path Params',
    //     name: 'vehicleId',
    //     description: 'Vehicle identifier',
    //   },
    // ],

    bodyParams: [
      {
        type: 'String',
        name: 'orderId',
        value: 'b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74',
        description: 'This is the unique identifier of the order on Symphony. ',
      },
      {
        type: 'String',
        name: 'orderNumber',
        value: '4701883578',
        description: 'Order number as recognised by BAT',
      },
      {
        type: 'String',
        name: 'orderType',
        value: 'STO | SO',
        description:
          'The type of order. Order types should be either ‘SO’ or ‘STO’',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'orderDate',
        value: '2022-10-05T01:00:00.000Z',
        description: 'Date of order creation.',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'deliveryDate',
        value: '2022-09-06 07:30:54.953141+00',
        description: 'Date when order will be processed & shipped.',
      },

      {
        type: 'Object',
        name: 'sourceLocation',
        value: `
{
  "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
  "label": "Dhaka Factory",
  "latitude": 23.7810153,
  "longitude": 90.39672089999999
}`,
        description: 'Order Source.',
      },

      {
        type: 'Object',
        name: 'destinationLocation',
        value: `
{
  "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
  "label": "Dhaka Factory",
  "latitude": 23.7810153,
  "longitude": 90.39672089999999
}`,
        description:
          'Order Destination. For SO orders, this will be distributor details.',
      },

      {
        type: 'Object []',
        name: 'orderItems',
        value: `
    [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
    ]`,
        description: 'List of SKUs and respective order quantities. ',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = [
  {
    "orderId": "b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74",
    "orderNumber": "4701883578",
    "orderType": "STO",
    "orderDate": "2022-10-05T01:00:00.000Z",
    "deliveryDate": "2022-10-05T01:00:00.000Z",
    "sourceLocation": {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    },
    "destinationLocation": {
      "locationId": "7fc554df-f587-499d-84f7-6f719bc75ac6",
      "label": "Sylhet",
      "latitude": 24.8950536,
      "longitude": 91.8688172
    },
    "orderItems": [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "a5bcbe91-82b5-42f3-a4e8-e16dc87cd2ef",
        "sku": 10065235,
        "name": "DERB RED 20/200 KRE SQ BAN SPARK",
        "category": "DERBY",
        "orderQuantity": 400,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      }
      
    ]
  }
]

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d [
  {
    "orderId": "b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74",
    "orderNumber": "4701883578",
    "orderType": "STO",
    "orderDate": "2022-10-05T01:00:00.000Z",
    "deliveryDate": "2022-10-05T01:00:00.000Z",
    "sourceLocation": {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    },
    "destinationLocation": {
      "locationId": "7fc554df-f587-499d-84f7-6f719bc75ac6",
      "label": "Sylhet",
      "latitude": 24.8950536,
      "longitude": 91.8688172
    },
    "orderItems": [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "a5bcbe91-82b5-42f3-a4e8-e16dc87cd2ef",
        "sku": 10065235,
        "name": "DERB RED 20/200 KRE SQ BAN SPARK",
        "category": "DERBY",
        "orderQuantity": 400,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      }
      
    ]
  }
]
`,
      },
    ],

    disablePlayground: true,
    requestUrl: '/orders',
    // testReqParams: [
    //   {
    //     type: 'string',
    //     name: 'vehicleId',
    //     required: true,
    //   },
    // ],
    fields: [
      {
        type: 'select',
        options: ['STO', 'SO'],
        name: 'orderType',
        required: true,
      },
      {
        type: 'string',
        name: 'geoFenceId',
        required: true,
      },
      {
        type: 'string',
        name: 'latitude',
        required: true,
      },
      {
        type: 'string',
        name: 'longitude',
        required: true,
      },
      {
        type: 'date',
        name: 'eta',
        required: true,
      },
    ],
  },

  {
    title: 'Update Order',
    description: `If orders are updated, the update information should also be relayed to BAT PT from Symphony. 
    The entire new updated order information should be resent to BAT PT only keeping the unique identifier same (orderId).`,
    anchor: 'orders',
    method: 'PUT',
    url: `${BASE_URL}/orders`,
    // params: [
    //   {
    //     type: 'Path Params',
    //     name: 'vehicleId',
    //     description: 'Vehicle identifier',
    //   },
    // ],

    bodyParams: [
      {
        type: 'String',
        name: 'orderId',
        value: 'b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74',
        description: 'This is the unique identifier of the order on Symphony. ',
      },
      {
        type: 'String',
        name: 'orderNumber',
        value: '4701883578',
        description: 'Order number as recognised by ',
      },
      {
        type: 'String',
        name: 'orderType',
        value: 'STO | SO',
        description:
          'The type of order. Order types should be either ‘SO’ or ‘STO’',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'orderDate',
        value: '2022-10-05T01:00:00.000Z',
        description: 'Date of order creation.',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'deliveryDate',
        value: '2022-09-06 07:30:54.953141+00',
        description: 'Date when order will be processed & shipped.',
      },

      {
        type: 'Object',
        name: 'sourceLocation',
        value: `
    {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    }`,
        description: 'Order Source.',
      },

      {
        type: 'Object',
        name: 'destinationLocation',
        value: `
    {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    }`,
        description:
          'Order Destination. For SO orders, this will be distributor details.',
      },

      {
        type: 'Object []',
        name: 'orderItems',
        value: `
    [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
    ]`,
        description: 'List of SKUs and respective order quantities. ',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = [
  {
    "orderId": "b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74",
    "orderNumber": "4701883578",
    "orderType": "STO",
    "orderDate": "2022-10-05T01:00:00.000Z",
    "deliveryDate": "2022-10-05T01:00:00.000Z",
    "sourceLocation": {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    },
    "destinationLocation": {
      "locationId": "7fc554df-f587-499d-84f7-6f719bc75ac6",
      "label": "Sylhet",
      "latitude": 24.8950536,
      "longitude": 91.8688172
    },
    "orderItems": [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "a5bcbe91-82b5-42f3-a4e8-e16dc87cd2ef",
        "sku": 10065235,
        "name": "DERB RED 20/200 KRE SQ BAN SPARK",
        "category": "DERBY",
        "orderQuantity": 400,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      }
      
    ]
  }
]

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d [
  {
    "orderId": "b0962bfb-db79-4bbb-b8e3-0b05ef9d3e74",
    "orderNumber": "4701883578",
    "orderType": "STO",
    "orderDate": "2022-10-05T01:00:00.000Z",
    "deliveryDate": "2022-10-05T01:00:00.000Z",
    "sourceLocation": {
      "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
      "label": "Dhaka Factory",
      "latitude": 23.7810153,
      "longitude": 90.39672089999999
    },
    "destinationLocation": {
      "locationId": "7fc554df-f587-499d-84f7-6f719bc75ac6",
      "label": "Sylhet",
      "latitude": 24.8950536,
      "longitude": 91.8688172
    },
    "orderItems": [
      {
        "productId": "e3a793c9-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "a5bcbe91-82b5-42f3-a4e8-e16dc87cd2ef",
        "sku": 10065235,
        "name": "DERB RED 20/200 KRE SQ BAN SPARK",
        "category": "DERBY",
        "orderQuantity": 400,
        "orderUnit": "Cartoons (10.50TH)"
      },
      {
        "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
        "sku": 10096869,
        "name": "STAR FFL 10/100 KRE SQ BAN MATDCBC",
        "category": "STAR",
        "orderQuantity": 443,
        "orderUnit": "Cartoons (10.50TH)"
      }
      
    ]
  }
]
`,
      },
    ],

    disablePlayground: true,
    requestUrl: '/orders',
    // testReqParams: [
    //   {
    //     type: 'string',
    //     name: 'vehicleId',
    //     required: true,
    //   },
    // ],
    fields: [
      {
        type: 'select',
        options: ['STO', 'SO'],
        name: 'orderType',
        required: true,
      },
      {
        type: 'string',
        name: 'geoFenceId',
        required: true,
      },
      {
        type: 'string',
        name: 'latitude',
        required: true,
      },
      {
        type: 'string',
        name: 'longitude',
        required: true,
      },
      {
        type: 'date',
        name: 'eta',
        required: true,
      },
    ],
  },

  {
    title: 'Start Scanning',
    description: `When an order is selected to be loaded onto a truck. Symphony needs to relay the Truck Number, Loading Bay Number, Date Time to BAT PT.  When an order is selected to be loaded onto a truck.
     Symphony needs to relay the Truck Number, Loading Bay Number, Date Time to BAT PT.`,
    anchor: 'orders',
    method: 'POST',
    url: `${BASE_URL}/orders/scan/start/{{orderId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'orderId',
        description: 'Unique identifier for order on Symphony.',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'orderAction',
        value: 'LOADING | UNLOADING',
        description:
          '"LOADING" # when scanning for package loading is starting or "UNLOADING" # when scanning for package unloading is starting',
      },
      {
        type: 'String',
        name: 'locationId',
        value: '799eee0e-740d-4624-9052-d70b4c31de77',
        description: 'Location ID of warehouse/factory.',
      },
      {
        type: 'String',
        name: 'truckNumberPlate',
        value: 'DMT-11-9647',
        description:
          'Truck number onto which consignment will be loaded on or unloaded from',
      },
      {
        type: 'String',
        name: 'bayNumber',
        value: 'Loading Bay 2',
        description: 'In which bay the trucks are being loaded or unloaded',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "orderAction": "LOADING",
  "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
  "truckNumberPlate": "DMT-11-9647",
  "bayNumber": "Loading Bay 2"
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "orderAction": "LOADING",
  "locationId": "799eee0e-740d-4624-9052-d70b4c31de77",
  "truckNumberPlate": "DMT-11-9647",
  "bayNumber": "Loading Bay 2"
}
`,
      },
    ],

    requestUrl: '/orders/scan/start/{{orderId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'orderId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'select',
        options: ['LOADING', 'UNLOADING'],
        name: 'orderAction',
        required: true,
      },
      {
        type: 'string',
        name: 'locationId',
        required: true,
      },
      {
        type: 'string',
        name: 'truckNumberPlate',
        required: true,
      },
      {
        type: 'string',
        name: 'bayNumber',
        required: true,
      },
    ],
  },

  {
    title: 'End Scanning',
    description:
      'When truck loading or unloading is complete, Symphony needs to relay that information to BAT PT. ',
    anchor: 'orders',
    method: 'POST',
    url: `${BASE_URL}/orders/scan/end/{{orderId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'orderId',
        description: 'Unique identifier for order on Symphony.',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'orderAction',
        value: 'LOADING | UNLOADING',
        description:
          '"LOADING" # when scanning for package loading has ended or "UNLOADING" # when scanning for package unloading has ended',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "orderAction": "LOADING",
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "orderAction": "LOADING",
}
`,
      },
    ],

    requestUrl: '/orders/scan/end/{{orderId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'orderId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'select',
        options: ['LOADING', 'UNLOADING'],
        name: 'orderAction',
        required: true,
      },
    ],
  },

  {
    title: 'Cancel Order',
    description:
      'If an order is canceled, BAT PT must be relayed that information.',
    anchor: 'orders',
    method: 'PATCH',
    url: `${BASE_URL}/orders/cancel/{{orderId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'orderId',
        description: 'order identifier',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'canceledBy',
        value: 'Jhon Doe',
        description: 'Name of the person canceling the order',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "canceledBy": "Json Doe",
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "canceledBy": "Jhon Doe",
}
`,
      },
    ],

    requestUrl: '/orders/cancel/{{orderId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'orderId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'string',
        name: 'canceledBy',
        required: true,
      },
    ],
  },

  {
    title: 'Scan Item',
    description:
      'When CBC scanning starts, scanning data should be sent in real time to BAT PT.',
    anchor: 'orders',
    method: 'POST',
    url: `${BASE_URL}/orders/scan/{{orderId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'orderId',
        description: 'order identifier',
      },
    ],

    bodyParams: [
      {
        type: 'String',
        name: 'scanAction',
        value: 'SCANNED IN | SCANNED OUT',
        description:
          '"SCANNED IN" # when package is unloaded from truck or "SCANNED OUT" # when package is loaded on to truck',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'scanDateTime',
        value: '2022-10-05T01:00:00.000Z',
        description: 'Date & Time of scan',
      },
      {
        type: 'String',
        name: 'productId',
        value: 'e3a793-de14-4944-9bcf-c4d915b74de7',
        description: 'ID of SKU (Product) as part of Order',
      },
      {
        type: 'String',
        name: 'inventoryItemId',
        value: 'ea1d829c-f819-418f-b2b2-34755675ba75',
        description: 'ID of inventory item for particular order',
      },
      {
        type: 'String',
        name: 'packCode',
        value: '29292J86',
        description: 'Item pack code',
      },
      {
        type: 'Datetime (UTC/BDT)',
        name: 'productionDate',
        value: '2022-10-05T01:00:00.000Z',
        description: 'Item production date',
      },
      {
        type: 'String',
        name: 'barcode',
        value: '44892852922JX0200830',
        description: 'Item barcode',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "scanAction": "SCANNED OUT",
  "scanDateTime": "2022-10-05T01:00:00.000Z",
  "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
  "inventoryItemId": "ea1d829c-f819-418f-b2b2-34755675ba75",
  "packCode": "29292J86",
  "productionDate": "2022-10-05T01:00:00.000Z",
  "barcode": "44892852922JX0200830"
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "scanAction": "SCANNED OUT",
  "scanDateTime": "2022-10-05T01:00:00.000Z",
  "productId": "e3a793-de14-4944-9bcf-c4d915b74de7",
  "inventoryItemId": "ea1d829c-f819-418f-b2b2-34755675ba75",
  "packCode": "29292J86",
  "productionDate": "2022-10-05T01:00:00.000Z",
  "barcode": "44892852922JX0200830"
}
`,
      },
    ],

    requestUrl: '/orders/scan/{{orderId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'orderId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'select',
        options: ['SCANNED IN', 'SCANNED OUT'],
        name: 'scanAction',
        required: true,
      },
      {
        type: 'string',
        name: 'productId',
        required: true,
      },
      {
        type: 'string',
        name: 'inventoryItemId',
        required: true,
      },
      {
        type: 'string',
        name: 'packCode',
        required: true,
      },
      {
        type: 'string',
        name: 'barcode',
        required: true,
      },
      {
        type: 'date',
        name: 'productionDate',
        required: true,
      },
      {
        type: 'date',
        name: 'scanDateTime',
        required: true,
      },
    ],
  },

  {
    title: 'Create Product',
    description:
      'When a new product is added on Symphony, the information needs to be relayed to BAT PT.',
    anchor: 'products',
    method: 'POST',
    url: `${BASE_URL}/products`,
    params: [
      // {
      //   type: 'Path Params',
      //   name: 'orderId',
      //   description: 'Unique identifier for order on Symphony.',
      // },
    ],

    bodyParams: [
      {
        type: 'string',
        name: 'productId',
        value: '759c844f-3237-4358-9290-468169fe982e',
        description: 'Unique identifier of product on Symphony.',
      },
      {
        type: 'string',
        name: 'name',
        value: 'STAR SWITC 20/200 KRE SQ BAN SWITCH',
        description: 'Name of product',
      },
      {
        type: 'string',
        name: 'sku',
        value: '29292J86',
        description: 'SKU of product',
      },
      {
        type: 'string',
        name: 'category',
        value: 'Switches',
        description: 'Category of product',
      },

      {
        type: 'string',
        name: 'subCategory',
        value: 'Switches',
        description: 'Sub category of product',
      },

      {
        type: 'string',
        name: 'packages',
        value: '1',
        description: 'Array of objects describing the packages of the product',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "productId": "759c844f-3237-4358-9290-468169fe982e",
  "name": "STAR SWITC 20/200 KRE SQ BAN SWITCH",
  "sku": "55451034",
  "category": "Star",
  "subCategory": [‘20 HL’, ‘ ’,’ ’,’ ’],
  "packages": "[
        {
          "id": "f554dc91-8149-4f00-9fc8-8708e3a6bc67",
          "name": "10's / 10 * 105 = 10.5 TH",
          "type": "CARTON",
          "width": "0",
          "widthUnit": "m",
          "height": "0",
          "heightUnit": "m",
          "length": "0",
          "lengthUnit": "m",
          "volume": "0",
          "volumeUnit": "m3",
          "size": 10500,
          "sizeUnit": "PIECE",
          "createdAt": "2022-05-29T17:19:04.291Z",
          "updatedAt": "2022-05-29T17:19:04.292Z"
        }
      ]
"
}

const response = await axios.post(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X POST 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "productId": "759c844f-3237-4358-9290-468169fe982e",
  "name": "STAR SWITC 20/200 KRE SQ BAN SWITCH",
  "sku": "55451034",
  "category": "Star",
  "subCategory": [‘20 HL’, ‘ ’,’ ’,’ ’],
  "packages": "[
        {
          "id": "f554dc91-8149-4f00-9fc8-8708e3a6bc67",
          "name": "10's / 10 * 105 = 10.5 TH",
          "type": "CARTON",
          "width": "0",
          "widthUnit": "m",
          "height": "0",
          "heightUnit": "m",
          "length": "0",
          "lengthUnit": "m",
          "volume": "0",
          "volumeUnit": "m3",
          "size": 10500,
          "sizeUnit": "PIECE",
          "createdAt": "2022-05-29T17:19:04.291Z",
          "updatedAt": "2022-05-29T17:19:04.292Z"
        }
      ]
"
}
`,
      },
    ],

    requestUrl: '/products',
    testReqParams: [],
    fields: [
      {
        type: 'string',
        name: 'productId',
        required: true,
      },
      {
        type: 'string',
        name: 'name',
        required: true,
      },
      {
        type: 'string',
        name: 'sku',
        required: true,
      },
      {
        type: 'string',
        name: 'category',
        required: true,
      },
      {
        type: 'string',

        name: 'subCategory',
        required: true,
      },
      {
        type: 'string',
        name: 'packages',
        required: true,
      },
    ],
  },

  {
    title: 'Update Product',
    description:
      'If products are updated, the update information should also be relayed to BAT PT from Symphony. The entire new updated product information should be resent to BAT PT only keeping the unique identifier same (productId).  ',
    anchor: 'products',
    method: 'PATCH',
    url: `${BASE_URL}/products/{{productId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'productId',
        description: 'Unique identifier for products on Symphony.',
      },
    ],

    bodyParams: [
      {
        type: 'string',
        name: 'name',
        value: 'STAR SWITC 20/200 KRE SQ BAN SWITCH',
        description: 'Name of product',
      },
      {
        type: 'string',
        name: 'sku',
        value: '29292J86',
        description: 'SKU of product',
      },
      {
        type: 'string',
        name: 'category',
        value: 'Switches',
        description: 'Category of product',
      },

      {
        type: 'string',
        name: 'subCategory',
        value: 'Switches',
        description: 'Sub category of product',
      },

      {
        type: 'string',
        name: 'packages',
        value: '1',
        description: 'Array of objects describing the packages of the product',
      },
      {
        type: 'string',
        name: 'deleted',
        value: 'false | true',
        description: 'Boolean value to indicate if the product is deleted',
      },
    ],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'

const requestBody = {
  "name": "STAR SWITC 20/200 KRE SQ BAN SWITCH",
  "sku": "55451034",
  "category": "Star",
  "subCategory": [‘20 HL’, ‘ ’,’ ’,’ ’],
  "packages": "[
        {
          "id": "f554dc91-8149-4f00-9fc8-8708e3a6bc67",
          "name": "10's / 10 * 105 = 10.5 TH",
          "type": "CARTON",
          "width": "0",
          "widthUnit": "m",
          "height": "0",
          "heightUnit": "m",
          "length": "0",
          "lengthUnit": "m",
          "volume": "0",
          "volumeUnit": "m3",
          "size": 10500,
          "sizeUnit": "PIECE",
          "createdAt": "2022-05-29T17:19:04.291Z",
          "updatedAt": "2022-05-29T17:19:04.292Z"
        }
      ]
"
}

const response = await axios.patch(
    '{{url}}',
    requestBody,
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X PATCH 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    -d {
  "name": "STAR SWITC 20/200 KRE SQ BAN SWITCH",
  "sku": "55451034",
  "category": "Star",
  "subCategory": [‘20 HL’, ‘ ’,’ ’,’ ’],
  "packages": "[
        {
          "id": "f554dc91-8149-4f00-9fc8-8708e3a6bc67",
          "name": "10's / 10 * 105 = 10.5 TH",
          "type": "CARTON",
          "width": "0",
          "widthUnit": "m",
          "height": "0",
          "heightUnit": "m",
          "length": "0",
          "lengthUnit": "m",
          "volume": "0",
          "volumeUnit": "m3",
          "size": 10500,
          "sizeUnit": "PIECE",
          "createdAt": "2022-05-29T17:19:04.291Z",
          "updatedAt": "2022-05-29T17:19:04.292Z"
        }
      ]
"
}
`,
      },
    ],

    requestUrl: '/products/{{productId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'productId',
        required: true,
      },
    ],
    fields: [
      {
        type: 'string',
        name: 'name',
        required: true,
      },
      {
        type: 'string',
        name: 'sku',
        required: true,
      },
      {
        type: 'string',
        name: 'category',
        required: true,
      },
      {
        type: 'string',

        name: 'subCategory',
        required: true,
      },
      {
        type: 'string',
        name: 'packages',
        required: true,
      },
    ],
  },
  {
    title: 'Delete Product',
    description:
      'If a product is deleted information should also be relayed to BAT PT from Symphony with productId.',
    anchor: 'products',
    method: 'DELETE',
    url: `${BASE_URL}/products/{{productId}}`,
    params: [
      {
        type: 'Path Params',
        name: 'productId',
        description: 'Unique identifier for product on Symphony.',
      },
    ],

    bodyParams: [],
    returnBody: [
      {
        type: 'BAD REQUEST',
        status: 400,
        body: {
          statusCode: 400,
          msg: 'Invalid data',
        },
      },
      {
        type: 'ERROR',
        status: 500,
        body: {
          statusCode: 500,
          msg: 'Sorry could not process your request',
        },
      },
      {
        type: 'SUCCESS',
        status: 200,
        body: {
          statusCode: 200,
          msg: 'Your request has been accepted',
        },
      },
    ],
    requestExamples: [
      {
        language: 'JS',
        sampleCode: `import axios from 'axios'



const response = await axios.delete(
    '{{url}}',
    {
    headers: {
            'x-bat-hub-api-key': {{API ACCESS KEY}}
            "Authorization": "Bearer {{Private Key}}" 
        }
    }
);

console.log(response.body) 
`,
      },
      {
        language: 'CURL',
        sampleCode: `curl -X DELETE 
    -H "Authorization: Bearer {{Private Key}}" 
    -H "x-bat-hub-api-key: {{API Access Key}}" 
    -H "Content-Type: application/json; charset=utf-8" 
    
`,
      },
    ],

    requestUrl: '/products/{{productId}}',
    testReqParams: [
      {
        type: 'string',
        name: 'productId',
        required: true,
      },
    ],
    fields: [],
  },
];
