import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuth: false,
  accessToken: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.user = null;
      state.accessToken = null;
    },
    signin: (state, action) => {
      state.isAuth = true;
      state.user = action.payload.user;
      state.accessToken = action.payload.sessionToken;
    },

    saveUserToken: (state, action) => {
      state.user = {
        ...state.user,
        token: action.payload,
      };
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
