import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from '../constants/app-routes';
import PrivateLayout from '../layouts/PrivateLayout';
import PublicLayout from '../layouts/PublicLayout';
import ProtectedPage from '../widgets/ProtectedPage';
import ScrollToTop from '../widgets/ScrollToTop';

const RootRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {routes.map((item) => {
          const { isProtected, label, Element, path } = item;
          if (isProtected) {
            return (
              <Route element={<PrivateLayout />} key={path}>
                <Route
                  path={path}
                  element={
                    <ProtectedPage>
                      <Element label={label} />
                    </ProtectedPage>
                  }
                />
              </Route>
            );
          } else {
            return (
              <Route element={<PublicLayout />} key={path}>
                <Route path={path} element={<Element label={label} />} />
              </Route>
            );
          }
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default RootRoutes;
