import axios from 'axios';
import { store } from '../store/index';

const getRequest = (method, url, data) => {
  let response;
  const privateKey = store.getState().auth.user?.token?.privateKey;
  const apiToken = store.getState().auth.user?.token?.apiToken;
  const config = {
    Authorization: `Bearer ${privateKey}`,
    'x-bat-hub-api-key': apiToken,
  };

  switch (method) {
    case 'POST':
      response = axios.post(url, { ...data }, { headers: config });
      break;
    case 'PATCH':
      response = axios.patch(url, { ...data }, { headers: config });
      break;
    case 'DELETE':
      response = axios.delete(url, { headers: config, params: { ...data } });
      break;
    default:
      response = axios.get(url, { headers: config, params: { ...data } });
      break;
  }
  return response;
};

export const makeTestSubmission = async (method, url, data) => {
  try {
    const response = await getRequest(method, url, data);

    return response?.data;
  } catch (error) {
    return {
      statusCode: error?.response?.data?.statusCode,
      msg: error?.response?.data?.msg,
    };
  }
};
