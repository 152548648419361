import { Stack, Typography } from '@mui/material';
import React from 'react';
import Helmet from 'react-helmet';
import Screen from '../widgets/Screen';

const Dashboard = ({ label }) => {
  return (
    <Screen>
      <Helmet>
        <title>BAT WMS | {label}</title>
      </Helmet>
      <Stack
        spacing={3}
        width="100%"
        height="calc(100vh - 79px)"
        alignItems="center"
        justifyContent="center">
        <Typography variant="h2" fontWeight={800}>
          Coming Soon
        </Typography>
        <Typography maxWidth={350} textAlign="center">
          Api analytics, logs and error trackings and more features are coming
          soon to this panel.
        </Typography>
      </Stack>
    </Screen>
  );
};

export default Dashboard;
