import {
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@mui/material';
import React from 'react';

const IntroductionDoc = () => {
  return (
    <Stack spacing={1.5} mb={10} id="getting-started">
      <Typography variant="h4" fontWeight={700}>
        Getting Started
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        BAT API Hub is an all in one developer platform built to allow easy
        integration support for various internal DBS projects and other 3rd
        party softwares. The developer portal you are currently seeing
        dynamically loads documentation relevant to your particular use cases.
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        Each API represents a particular use case that covers the integration
        requirement for the business logic. Please see all API lists under the{' '}
        <b>‘Use Cases’</b> tab on the left.
      </Typography>
      <Typography fontSize={20} fontWeight={700}>
        Usage Limit
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        At present we have a query limit in place in order to make sure our
        internal systems are not overwhelmed and have fair availability to other
        stakeholders. The query limit & API rules mentioned below are unique to
        your use case, this limit may be different depending on the use case.
      </Typography>
      <Table
        size="small"
        sx={{ border: '2px solid rgba(0,0,0,0.1)', maxWidth: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>Environment</TableCell>
            <TableCell sx={{ fontWeight: 700 }}>Requests/sec</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>SANDBOX</TableCell>
            <TableCell>50/sec</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>PRODUCTION</TableCell>
            <TableCell>1000/sec</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        If you cross the query limit, there is a 30 second cool down period.
        After 30 seconds you will again be allowed to query.
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        You will receive the following response in cases where you reach maximum
        query limit:
      </Typography>
      <pre className="code">
        {JSON.stringify(
          {
            statusCode: 429,
            msg: 'Too many requests',
          },
          null,
          2,
        )}
      </pre>
      <Typography fontSize={20} fontWeight={700}>
        Help & Support
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        For more help or clarification, please reach out to us at{' '}
        <b>devs@batbangladesh.dev</b>
      </Typography>
      <Typography fontSize={20} fontWeight={700}>
        Context
      </Typography>
      <Typography
        fontWeight={400}
        maxWidth={'100%'}
        color="#303030"
        fontSize={14}>
        BAT Planning Tool requires Symphony Track & Trace (Nuport) to relay data
        to its endpoints in real time. BAT Planning Tool will provide a list of
        REST API endpoints that will function as hooks for Symphony Track &
        Trace to send across data during particular event triggers.
      </Typography>
    </Stack>
  );
};

export default IntroductionDoc;
