import { combineReducers } from 'redux';
import authReducer from './auth-reducer';
import errorReducer from './error-reducer';

const reducers = combineReducers({
  auth: authReducer,
  errors: errorReducer,
});

export default reducers;
