import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';

import { sidebarItems, sidebarItems2 } from '../../constants/sidebar-items';

const Sidebar = ({ isOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Stack
      justifyContent="space-between"
      height="100%"
      sx={{ bgcolor: '#171820' }}>
      <List sx={{ flexGrow: 1 }}>
        {sidebarItems.map((item) => {
          const { path, label, Icon } = item;
          const isSelected = location.pathname.includes(path);

          return (
            <Tooltip key={path} title={isOpen ? '' : label} placement="right">
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() => {
                    if (label === 'API Docs') {
                      navigate(path + encodeURI('/Getting Started'));
                    } else {
                      navigate(path);
                    }
                  }}
                  disabled={item.isDisabled}
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Icon
                      sx={{
                        fill: isSelected ? '#4562f7' : '#DDDDDD',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                    style={{ color: 'white' }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
      <Divider />
      <List sx={{ flexGrow: 0 }}>
        {sidebarItems2.map((item) => {
          const { path, label, Icon } = item;
          const isSelected = location.pathname.endsWith(path);

          return (
            <Tooltip key={path} title={isOpen ? '' : label} placement="right">
              <ListItem
                disablePadding
                sx={{ display: 'block' }}
                onClick={() => {
                  if (label === '') {
                    navigate(path + encodeURI('/Getting Started'));
                  } else {
                    navigate(path);
                  }
                }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}>
                    <Icon
                      sx={{
                        fill: isSelected ? '#4562f7' : '#DDDDDD',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{ opacity: isOpen ? 1 : 0 }}
                    style={{ color: 'white' }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Stack>
  );
};

export default Sidebar;
