import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../widgets/Button';
import { authActions } from '../store/reducers/auth-reducer';
import { Navigate } from 'react-router-dom';
import { DOCS } from '../constants/app-urls';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SignInSchema } from '../validation/auth';
import { signinUser } from '../services/auth';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Signin = () => {
  const dispacth = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const resolver = yupResolver(SignInSchema);
  const [isLoading, setIsLoading] = useState(false);
  const [filedType, setFieldType] = useState('password');
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange', resolver });

  const handleSignIn = async (data) => {
    const { username, password } = data;

    setIsLoading(true);
    const response = await signinUser(username, password);
    setIsLoading(false);

    if (response) {
      dispacth(
        authActions.signin({
          user: response.user,
          sessionToken: response.token,
        }),
      );
    }
  };

  if (isAuth) {
    return <Navigate to={DOCS + encodeURI('/Getting Started')} />;
  }
  return (
    <Stack
      width="100%"
      height="calc(100vh - 15px)"
      alignItems="center"
      justifyContent="center"
      spacing={2}>
      <Box component="img" src="/assets/logo.png" width={250} />
      <Typography fontSize={26} textAlign="center" fontWeight={700}>
        API HUB
      </Typography>
      <TextField
        size="small"
        fullWidth
        sx={{ maxWidth: 300 }}
        label="Username"
        placeholder="Please enter your username"
        {...register('username')}
        error={!!errors?.username}
        helperText={errors?.username?.message}
      />
      <TextField
        size="small"
        fullWidth
        sx={{ maxWidth: 300 }}
        label="Password"
        placeholder="Please enter your password"
        {...register('password')}
        error={!!errors?.password}
        helperText={errors?.password?.message}
        type={filedType}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setFieldType((previousFieldType) => {
                  if (previousFieldType === 'password') {
                    return 'text';
                  } else {
                    return 'password';
                  }
                });
              }}>
              {filedType === 'password' ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          ),
        }}
      />
      <Button
        loading={isLoading}
        variant="contained"
        fullWidth
        sx={{ maxWidth: 300 }}
        onClick={handleSubmit(handleSignIn)}>
        Sign In
      </Button>
      <Typography fontSize={12} fontWeight={300}>
        Version: 1.0.0-beta
      </Typography>
    </Stack>
  );
};

export default Signin;
