import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  Stack,
  Typography,
  Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { MANAGE_TOKEN } from '../../constants/app-urls';
import { makeTestSubmission } from '../../services/test-submission';
import { errorNoitif } from '../../utils/notifications';
import Button from '../../widgets/Button';
const baseURL = process.env.REACT_APP_API_BASEURL;

const RenderField = ({ field, register }) => {
  switch (field.type) {
    case 'select':
      return (
        <FormControl size="small" fullWidth>
          <Typography textAlign="left" sx={{ width: '100%' }}>
            {field.name}{' '}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <Select {...register(`${field.name}`)} required={field.required}>
            {field.options.map((item) => (
              <MenuItem value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case 'string':
      return (
        <Stack width="100%">
          <Typography textAlign="left" sx={{ width: '100%' }}>
            {field.name}{' '}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <TextField
            required={field.required}
            {...register(`${field.name}`)}
            size="small"
            fullWidth
          />
        </Stack>
      );
    case 'date':
      return (
        <Stack width="100%">
          <Typography textAlign="left" sx={{ width: '100%' }}>
            {field.name}{' '}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <TextField
            required={field.required}
            {...register(`${field.name}`)}
            type="datetime-local"
            size="small"
            fullWidth
          />
        </Stack>
      );
    default:
      return (
        <Stack width="100%">
          <Typography textAlign="left" sx={{ width: '100%' }}>
            {field.name}{' '}
            {field.required && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <TextField
            required={field.required}
            {...register(`${field.name}`)}
            size="small"
            fullWidth
          />
        </Stack>
      );
  }
};

const DocPlayground = ({ requestUrl, testReqParams, fields, method }) => {
  const { register, handleSubmit } = useForm();
  const [responseData, setResponseData] = useState({});
  const [url, setUrl] = useState('');
  const [needsTokenGeneration, setNeedTokenGeneration] = useState(false);

  const replaceBaseUrl = () => {
    let tempUrl = `${requestUrl}`;
    tempUrl = tempUrl.replace('{{base_url}}', baseURL.replace('/v1', ''));
    setUrl(tempUrl);
  };

  useEffect(() => replaceBaseUrl(), []);

  const makeUrl = (data) => {
    let tempUrl = url;

    testReqParams?.map((item) => {
      tempUrl = tempUrl.replace(`{{${item.name}}}`, data?.[item.name]);
    });

    setUrl(tempUrl);
    return tempUrl;
  };

  const getSubmissionData = (data) => {
    let submissionData = {};

    fields?.map((item) => {
      submissionData = {
        ...submissionData,
        [item.name]: data?.[item.name],
      };
    });

    return submissionData;
  };

  const validate = (data) => {
    let isValid = true;

    testReqParams.map((item) => {
      if (!data[item?.name]) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleTestSubmission = async (data) => {
    const submissionUrl = makeUrl(data);

    if (validate(data)) {
      const submissionData = getSubmissionData(data);

      const response = await makeTestSubmission(
        method,
        submissionUrl,
        submissionData,
      );

      if (response.statusCode === 401) {
        setNeedTokenGeneration(true);
      } else {
        setNeedTokenGeneration(false);
      }
      setResponseData(response);
      replaceBaseUrl();
    } else {
      errorNoitif('Please enter value for request params');
    }
  };

  return (
    <>
      <Stack
        spacing={2}
        maxWidth={850}
        alignItems="center"
        justifyContent="center">
        <Typography
          sx={{
            width: '100%',
            textAlign: 'left',
            fontSize: 15,
            fontWeight: 700,
          }}>
          Request Params
        </Typography>
        {testReqParams?.map((item) => (
          <RenderField key={item.name} field={item} register={register} />
        ))}
        <Typography
          sx={{
            width: '100%',
            textAlign: 'left',
            fontSize: 15,
            fontWeight: 700,
          }}>
          Body Params
        </Typography>
        {fields?.map((item) => (
          <RenderField key={item.name} field={item} register={register} />
        ))}
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Typography fontSize={20} fontWeight={700}>
            Server Response
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleSubmit(handleTestSubmission)}>
            Send Test Request
          </Button>
        </Stack>
      </Stack>
      {needsTokenGeneration && (
        <Alert severity="error" sx={{ maxWidth: 850 }}>
          <Typography>
            It seems like you don&apos;t have a sand box token generated. You
            can generate one from{' '}
            <Link to={MANAGE_TOKEN}>API Credential section</Link>
          </Typography>
        </Alert>
      )}
      <pre className="code">{JSON.stringify(responseData, null, 2)}</pre>
    </>
  );
};

export default DocPlayground;
