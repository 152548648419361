import React from 'react';
import Helmet from 'react-helmet';
import Screen from '../widgets/Screen';

const Settings = ({ label }) => {
  return (
    <Screen>
      <Helmet>
        <title>BAT API HUB | {label}</title>
      </Helmet>
    </Screen>
  );
};

export default Settings;
