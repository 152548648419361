import { styled, Tabs as MuiTabs } from '@mui/material';

const Tabs = styled(MuiTabs)({
  width: '100%',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

export default Tabs;
