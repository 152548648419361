import { Stack } from '@mui/material';
import React from 'react';

const Branding = () => {
  return (
    <Stack
      width="100%"
      height={15}
      sx={{
        position: 'fixed',
        bottom: 0,
        background:
          'linear-gradient(90deg, #0e2b63 30.4785%, rgba(0, 0, 0, 0) 30.4785%), linear-gradient(90deg, #004f9f 52.15788%, rgba(0, 0, 0, 0) 52.15788%), linear-gradient(90deg, #00b1eb 64.72807%, rgba(0, 0, 0, 0) 64.72807%), linear-gradient(90deg, #ef7d00 73.22551%, rgba(0, 0, 0, 0) 73.22551%), linear-gradient(90deg, #ffbb00 79.62792%, rgba(0, 0, 0, 0) 79.62792%), linear-gradient(90deg, #50af47 86.69237%, rgba(0, 0, 0, 0) 86.69237%), linear-gradient(90deg, #afca0b 92.0305%, rgba(0, 0, 0, 0) 92.0305%), linear-gradient(90deg, #5a328a 97.0083%, rgba(0, 0, 0, 0) 97.0083%), linear-gradient(90deg, #e72582 100%, rgba(0, 0, 0, 0) 100%)',
      }}
    />
  );
};

export default Branding;
