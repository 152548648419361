import Dashboard from '../pages/Dashboard';
import Docs from '../pages/Docs';
import Settings from '../pages/Settings';
import Signin from '../pages/Signin';
import TokenManagement from '../pages/TokenManagement';
import * as urls from './app-urls';

const routes = [
  {
    isProtected: false,
    path: urls.SIGNIN,
    Element: Signin,
    label: 'Signin',
  },
  {
    isProtected: true,
    path: urls.DASHBOARD,
    Element: Dashboard,
    label: 'Dashboard',
  },
  {
    isProtected: true,
    path: urls.SETTINGS,
    Element: Settings,
    label: 'Settings',
  },
  {
    isProtected: true,
    path: urls.DOCS + '/:title',
    Element: Docs,
    label: 'Documentation',
  },
  {
    isProtected: true,
    path: urls.MANAGE_TOKEN,
    Element: TokenManagement,
    label: 'API Credentials',
  },
];

export default routes;
