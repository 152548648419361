import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#004f9f',
    },
    text: {
      primary: '#303030',
    },
  },
  typography: {
    // eslint-disable-next-line quotes
    fontFamily: "'Poppins', sans-serif",
  },
});

export default theme;
