import { styled, Tab as MuiTab } from '@mui/material';

const Tab = styled(MuiTab)({
  fontSize: '0.9rem',
  letterSpacing: 0.8,
  fontWeight: 700,
  textTransform: 'capitalize',
  backgroundColor: '#303030',
  marginBottom: '5px',
  marginRight: '5px',
  borderRadius: '3px',
  color: 'white',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: '#004f9f',
    borderRadius: '3px',
    transition: '0.3s ease',
  },
});

export default Tab;
