import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import RootRoutes from './routes/RootRoutes';
import { persistor, store } from './store';
import theme from './theme/theme';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootRoutes />
          <ToastContainer />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
