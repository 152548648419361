import { Get, Patch, Post } from './request';

export const generateToken = async (tokenType) => {
  const response = await Post('/token', { tokenType });

  if (response) {
    return response.token;
  }

  return null;
};

export const fetchAllToken = async () => {
  const response = await Get('/token');
  if (response) {
    return response.tokens;
  }

  return [];
};

export const invalidateToken = async (tokenId) => {
  const response = await Patch(`/token/${tokenId}`);

  if (response) {
    return true;
  }
  return false;
};
