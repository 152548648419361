import { Cancel, Close, CopyAll, Download } from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch } from 'react-redux';
import {
  fetchAllToken,
  generateToken,
  invalidateToken,
} from '../services/api-token';
import { authActions } from '../store/reducers/auth-reducer';
import { formatDate } from '../utils/date';
import { errorNoitif } from '../utils/notifications';
import Button from '../widgets/Button';
import Screen from '../widgets/Screen';
import { generateAndDownloadCsv } from '../utils/csv';

const TokenManagement = ({ label }) => {
  const dispatch = useDispatch();

  const [tokens, setTokens] = useState([]);
  const [showTokenGenerationModal, setShowTokenGenerationModal] =
    useState(false);
  const [showConfirmTokenGenerationModal, setShowConfirmTokenGenerationModal] =
    useState(false);
  const [showTokenExpireConfirmModal, setShowTokenExpireConfirmModal] =
    useState(false);
  const [selectedTokenType, setSelectedTokenType] = useState('SANDBOX');
  const [expiryTokenPatchId, setExpiryTokenPatchId] = useState(null);
  const [showTokenViewDialog, setShowTokenViewDialog] = useState(false);
  const [generatedToken, setGeneratedToken] = useState(null);
  const [popperAnchor, setPopperAnchor] = useState(null);
  const openPopper = Boolean(popperAnchor);

  useEffect(() => {
    const timeout = setTimeout(() => setPopperAnchor(null), 600);

    () => clearTimeout(timeout);
  }, [popperAnchor]);

  const handleTokenFetch = async () => {
    const response = await fetchAllToken();
    setTokens(response);
  };

  useEffect(() => {
    (async () => await handleTokenFetch())();
  }, []);

  const copyTokenToClipBoard = (token) => {
    navigator.clipboard.writeText(token);
  };

  const columns = [
    {
      field: 'issuedOn',
      headerName: 'Issued On',
      renderCell: (params) => formatDate(params.value),
      width: 180,
    },
    {
      field: 'tokenType',
      headerName: 'Type',
      width: 120,
    },
    {
      field: 'apiToken',
      headerName: 'API Access Key',
      width: 250,

      renderCell: (params) => {
        if (params.row.status === 'VALID') {
          return (
            <Stack direction="row" spacing={2}>
              <Typography fontSize={14}>{params.value}</Typography>
              <IconButton
                size="small"
                onClick={() => copyTokenToClipBoard(params.value)}>
                <CopyAll />
              </IconButton>
            </Stack>
          );
        }

        return (
          <Stack direction="row" spacing={1}>
            <Typography sx={{ textDecoration: 'line-through' }} fontSize={14}>
              {params.value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => {
        if (params.value === 'EXPIRED') {
          return <Chip label={params.value} color="error" size="small" />;
        }

        return <Chip label={params.value} color="success" size="small" />;
      },
    },

    {
      field: 'expiry',
      headerName: 'Expiry',
      renderCell: (params) => formatDate(params.value),
      width: 180,
    },
    {
      field: 'tokenId',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <IconButton
            title="Invalidate token"
            color="error"
            disabled={params.row.status === 'EXPIRED'}
            onClick={() => {
              setShowTokenExpireConfirmModal(true);
              setExpiryTokenPatchId(params.value);
            }}>
            <Cancel />
          </IconButton>
        );
      },
    },
  ];

  const handleTokenInvalidation = async () => {
    const isTokenInvalidated = await invalidateToken(expiryTokenPatchId);
    if (isTokenInvalidated) {
      setTokens((items) =>
        items.map((x) =>
          x.tokenId === expiryTokenPatchId ? { ...x, status: 'EXPIRED' } : x,
        ),
      );
      setShowTokenExpireConfirmModal(false);
      return;
    }
    setShowTokenExpireConfirmModal(false);
    errorNoitif('Failed to invalidate token');
  };

  const handleTokenGeneration = async () => {
    const response = await generateToken(selectedTokenType);
    if (response) {
      if (selectedTokenType === 'SANDBOX') {
        dispatch(authActions.saveUserToken(response));
      }
      await handleTokenFetch();
    }

    setGeneratedToken(response);
    setShowTokenViewDialog(true);

    setShowTokenGenerationModal(false);
    setShowConfirmTokenGenerationModal(false);
  };

  const handleTokenGenerationConfirmation = () => {
    setShowTokenGenerationModal(false);
    setShowConfirmTokenGenerationModal(true);
  };

  const handleTokenGenerationRejection = () => {
    setShowConfirmTokenGenerationModal(false);
    setShowTokenGenerationModal(true);
  };

  const downloadCsv = (apiKey, privateKey) => {
    const rows = [
      ['API Key', 'Private Key'],
      [`${apiKey || ''}`, `${privateKey || ''}`],
    ];

    generateAndDownloadCsv(rows);
  };

  return (
    <Screen>
      <Helmet>
        <title>BAT API HUB | {label}</title>
      </Helmet>
      <Popper open={openPopper} anchorEl={popperAnchor}>
        <Typography>Coppied</Typography>
      </Popper>
      <Dialog
        onClose={() => {
          setShowTokenViewDialog(false);
          setGeneratedToken(null);
        }}
        open={showTokenViewDialog}>
        <DialogContent>
          <Stack position="relative" spacing={1}>
            <Typography variant="h6" fontWeight={700}>
              Generated Token
            </Typography>
            {!generatedToken?.apiToken && !generatedToken?.privateKey ? (
              <Alert severity="warning">
                <Typography>
                  You can only have one valid <b>PRODUCTION</b> key at any
                  moment in time. You must invalidate previous <b>PRODUCTION</b>{' '}
                  key before creation a new one.
                </Typography>
              </Alert>
            ) : (
              <Alert severity="warning">
                <Typography>
                  This is the only time you will be able to see <b>API key</b> &{' '}
                  <b>privateKey (Server Auth Header)</b>. Please copy down the
                  credentials for future use.
                </Typography>
              </Alert>
            )}
            {generatedToken?.apiToken && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontWeight={800}>API Key: </Typography>
                <pre className="url">{generatedToken?.apiToken}</pre>
                <IconButton
                  onClick={() => {
                    setPopperAnchor(event.currentTarget);
                    copyTokenToClipBoard(generatedToken?.apiToken);
                  }}>
                  <CopyAll />
                </IconButton>
              </Stack>
            )}
            {generatedToken?.privateKey && (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography fontWeight={800}>Private Key: </Typography>
                <pre className="url">{generatedToken?.privateKey}</pre>
                <IconButton
                  onClick={(event) => {
                    setPopperAnchor(event.currentTarget);
                    copyTokenToClipBoard(generatedToken?.privateKey);
                  }}>
                  <CopyAll />
                </IconButton>
              </Stack>
            )}
            <Stack direction="row" spacing={1} justifyContent="flex-end">
              <Button
                endIcon={<Close />}
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setShowTokenViewDialog(false);
                  setGeneratedToken(null);
                }}>
                Close
              </Button>
              <Button
                endIcon={<Download />}
                variant="contained"
                size="small"
                onClick={() =>
                  downloadCsv(
                    generatedToken?.apiToken,
                    generatedToken?.privateKey,
                  )
                }
                disabled={
                  !generatedToken?.apiToken && !generatedToken?.privateKey
                }>
                Download CSV
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showConfirmTokenGenerationModal}
        onClose={() => setShowConfirmTokenGenerationModal(false)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h6">Are You Sure?</Typography>
            <Typography>
              Are you sure you want to generate a new <b>{selectedTokenType}</b>{' '}
              API key?
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              width="100%">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={handleTokenGenerationRejection}>
                No
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleTokenGeneration}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={showTokenExpireConfirmModal}
        onClose={() => setShowTokenExpireConfirmModal(false)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h6">Are You Sure?</Typography>
            <Typography>
              Are you sure you want to invalidate this token?
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              width="100%">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => {
                  setShowTokenExpireConfirmModal(false);
                  setExpiryTokenPatchId(null);
                }}>
                No
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={handleTokenInvalidation}>
                Yes
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={showTokenGenerationModal}
        onClose={() => setShowTokenGenerationModal(false)}>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h6" fontWeight={700}>
              Generate API Key
            </Typography>
            <Alert severity="warning">
              <Typography>
                You can only have one valid <b>PRODUCTION</b> key at any moment
                in time. You must invalidate previous <b>PRODUCTION</b> key
                before creation a new one.
              </Typography>
            </Alert>
            <FormControl>
              <InputLabel>Token Type</InputLabel>
              <Select
                label="Token Type"
                value={selectedTokenType}
                onChange={(event) => setSelectedTokenType(event.target.value)}>
                <MenuItem value="SANDBOX">Sandbox</MenuItem>
                <MenuItem value="PRODUCTION">Production</MenuItem>
              </Select>
            </FormControl>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              width="100%">
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={() => setShowTokenGenerationModal(false)}>
                Close
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={handleTokenGenerationConfirmation}>
                Generate API Key
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack
        width="70%"
        mx="auto"
        justifyContent="center"
        height="calc(100vh - 79px)"
        boxSizing="border-box"
        py={3}
        spacing={2}>
        <Stack
          width="100%"
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between">
          <Typography variant="h5" fontWeight={700}>
            API Credentials
          </Typography>
          <Button
            onClick={() => setShowTokenGenerationModal(true)}
            variant="contained">
            Generate Key
          </Button>
        </Stack>
        <Box width="100%" height={350}>
          <DataGrid
            columns={columns}
            rows={tokens}
            getRowId={(row) => row.tokenId}
            density="compact"
            components={{ Pagination: null }}
          />
        </Box>
      </Stack>
    </Screen>
  );
};

export default TokenManagement;
